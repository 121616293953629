import { Col } from 'antd';
import { AddressLabels } from 'models/Address';
import { DefaultOptionType } from 'rc-cascader';
import { RawValueType } from 'rc-select/lib/BaseSelect';
import { LabelInValueType } from 'rc-select/lib/Select';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCountryAlpha2CodeEquals, setSubdivisionLocalCodeEquals } from 'redux/services/chuckieSue/addressesParams';
import { useGetWorldsQuery } from 'redux/services/chuckieSue/worldsApi';
import { useAppSelector } from 'redux/store';
import { FancySelect } from '../Inputs';

export const CountryStateInputs: React.FC = () => {
  const { subdivisionLocalCodeEquals, countryAlpha2CodeEquals } = useAppSelector((state) => state.chuckieSueAddressesParams);
  const { fullOutageAddress } = useAppSelector((state) => state.form);
  const { acuityContext } = useAppSelector((state) => state.app);

  const [selectedCountry, setSelectedCountry] = useState<RawValueType | LabelInValueType>('United States of America');
  const dispatch = useDispatch();

  const { data } = useGetWorldsQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({ data: data?.data[0].countries.find((country) => country.shortName === selectedCountry), ...rest })
  });
  const { data: countries } = useGetWorldsQuery();

  const countryNames = countries?.data[0].countries.map((country) => ({ label: country.shortName, value: country.shortName }));

  const stateOptions: DefaultOptionType[] | undefined = data?.subdivisions.map((state) => ({ label: state.name, value: state.localCode }));

  const findInstallCountry = fullOutageAddress ? countries?.data[0].countries.find((country) => country.alpha2Code === fullOutageAddress.countryAlpha2Code) : undefined;

  useEffect(() => {
    if (findInstallCountry && acuityContext?.configuration?.addressDetails.displayAddressesByDefault) {
      dispatch(setCountryAlpha2CodeEquals(findInstallCountry.alpha2Code));

      return;
    }
    if (countries && acuityContext?.configuration?.addressDetails.displayAddressesByDefault) {
      dispatch(setCountryAlpha2CodeEquals('US'));
      dispatch(setSubdivisionLocalCodeEquals(undefined));
    }
  }, []);
  const handleStateChange = (value: string): void => {
    dispatch(setSubdivisionLocalCodeEquals(value));
  };

  const handleCountryChange = (value: string): void => {
    setSelectedCountry(value);
    const countryAlpha2Code = () => {
      const country = countries?.data[0].countries.find((country) => country.shortName === value);

      return country?.alpha2Code;
    };

    dispatch(setCountryAlpha2CodeEquals(countryAlpha2Code()));
    dispatch(setSubdivisionLocalCodeEquals(undefined));
  };

  return (
    // <Row gutter={[8, 8]}>
    <>
      <Col span={6}>
        <FancySelect
          initialTop={!!countryAlpha2CodeEquals}
          placeholder="Country"
          style={{ margin: 0 }}
          defaultActiveFirstOption={true}
          defaultOpen={false}
          value={countryAlpha2CodeEquals}
          onChange={handleCountryChange}
          showSearch
          options={countryNames ? countryNames : undefined}
        />
      </Col>
      <Col span={6}>
        <FancySelect
          showSearch
          placeholder={AddressLabels.subdivisionLocalCode}
          onChange={handleStateChange}
          onDeselect={(): any => dispatch(setSubdivisionLocalCodeEquals(undefined))}
          options={stateOptions}
          value={subdivisionLocalCodeEquals}
        />
      </Col>
    </>
    // </Row>
  );
};
